.ease-3 {
  transition: all 0.3s ease;
}

.notfound-container {
  width: 100%;
  height: calc(100vh - 110px);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  background-color: #f5f5f5;
}

.notfound-wrapper {
  width: 1170px;
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
}

.notfound--main-box {
  height: 280px;
  width: 70%;
  padding: 26px;
  background: url('../img/404-box_text.png') no-repeat, linear-gradient(352deg, rgba(0,0,0,1) 7%, rgba(34,34,34,1) 52%, rgba(51,51,51,1) 100%);
  background-position: bottom right;
  background-size: 100%;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-box--title {
  font-family: 'Octavian Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 3rem;
  color: #fa7268;
  margin-bottom: 16px;
}

.main-box--text {
  color: #eaeaea;
  width: 40%;
  flex-grow: 1;
}

.main-box--buttons {
  justify-content: flex-start;
  width: auto;
}

.main-box--button {
  height: auto;
  width: auto;
  padding: 12px 16px 8px 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: 'Octavian Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  background-color: #fa7268;
  color: #fff;
  border-radius: 3px;
  text-decoration: none;
}

.main-box--button:hover {
  background-color: #fff;
  color: #222;
}

.notfound--cs-box {
  height: 280px;
  width: 26%;
  background: url('../img/man_phone-yelling.jpg') no-repeat;
  background-size: 155%;
  border-radius: 3px;
  padding: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.secondary-box--title {
  font-family: 'Octavian Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2rem;
  color: #fa7268;
}

.cs-box--button {
  height: auto;
  width: 100%;
  padding: 12px 0 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Octavian Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  background-color: #222;
  color: #fff;
  border-radius: 3px;
  text-decoration: none;
}

.cs-box--button:hover {
  background-color: #fa7268;
  color: #fff;
}

.notfound--report-box {
  height: auto;
  width: 100%;
  padding: 26px;
  background: #fff url('../img/report_ghosty-bg.png') no-repeat;
  background-size: 72%;
  background-position: top right;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.report-box--text {
  color: #222;
  margin-top: 16px;
}

.notfound--main-box, .notfound--cs-box, .notfound--report-box {
  box-shadow: 0 1px 2px rgba(0,0,0,0.02), 
              0 2px 4px rgba(0,0,0,0.02), 
              0 4px 8px rgba(0,0,0,0.02), 
              0 8px 16px rgba(0,0,0,0.02),
              0 16px 32px rgba(0,0,0,0.02), 
              0 32px 64px rgba(0,0,0,0.02);
}

@media only screen and (max-width: 1195px) {
  .notfound-wrapper {
    width: 100%;
    padding: 0 1rem;
  }
}

@media only screen and (max-width: 1195px) {
  .notfound--main-box {
    background-position: top right;
    background-size: cover;
  }

  .notfound--cs-box {
    background-size: cover;
  }
}

@media only screen and (max-width: 1125px) {
  .notfound-container {
    height: calc(100vh - 54px);
  }

  .main-box--text {
    width: 40%;
  }
}

@media only screen and (max-width: 1025px) {
  .notfound-wrapper {
    margin: 0;
    padding: 0;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .notfound--main-box {
    align-self: stretch;
    border-radius: 0;
    width: 100%;
    flex-grow: 1;
  }

  .main-box--title {
    color: #fff;
  }

  .main-box--text {
    width: 60%;
  }

  .notfound--cs-box {
    width: 100%;
    background-size: cover;
    background-position: center;
  }

  .cs-box--button {
    width: 186px;
    display: inline-flex;
  }

  .notfound--report-box {
    height: 160px;
  }
}

@media only screen and (max-width: 600px) {
  .notfound--main-box {
    padding: 1rem;
  }

  .main-box--button {
    background-color: #222;
  }

  .main-box--button:hover {
    background-color: #fa7268;
    color: #fff;
  }

  .cs-box--button {
    width: 100%;
  }

  .notfound--cs-box {
    background-position: top;
    padding: 1rem;
  }

  .notfound--report-box {
    padding: 1rem;
    height: auto;
    background-size: 110%;
    background-position: 100% 50%;
  }

  .report-box--text {
    margin-top: 5rem;
  }
}